<template>
  <v-card class="pa-4" flat>
    <div class="d-flex flex-auto flex-wrap">
      <div>
        <v-btn
          v-if="g_role_type != 'STUDENT'"
          :disabled="dataTable.data.length === 0"
          class="gradient-primary caption mr-2"
          dark
          dense
          depressed
        >
          <download-excel
            :data="dataTable.data"
            :fields="excel"
            :name="'Extracurricular.xls'"
            class="d-inline"
          >
            <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
              {{ $t("app.download") }}
            </span>
            <v-icon>mdi-cloud-download</v-icon>
          </download-excel>
        </v-btn>
        <div class="d-inline font-weight-bold subtitle-2">
          {{ dataTable.data.length }} {{ $t("routes.extracurricular.short") }}
        </div>
      </div>

      <v-spacer />

      <v-text-field
        v-if="g_role_type != 'STUDENT'"
        v-model="search"
        :label="$t('app.search')"
        :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
        class="d-inline-block mr-2"
        outlined
        dense
        hide-details
        clearable
        style="max-width: 250px"
        prepend-inner-icon="mdi-magnify"
      />
      <div v-if="isAdmin">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
          <v-btn
            class="gradient-primary caption mr-2"
            dark
            dense
            depressed
            @click="toggleDialogMaster"
          >
            Master
            <v-icon class="ml-1">mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn
            class="gradient-primary caption mr-2"
            dark
            dense
            depressed
            @click="toggleFormDialog(false)"
          >
            {{ $t("routes.extracurricular.short") }}
            <v-icon class="ml-1">mdi-plus-box</v-icon>
          </v-btn>
          <v-btn
            class="gradient-primary caption"
            dark
            dense
            depressed
            @click="$router.push({ name: 'ExtracurricularMember' })"
          >
            {{ $t("extracuricular.new_member") }}
            <v-icon>mdi-exclamation</v-icon>
          </v-btn>
        </div>
        <div v-else class="d-flex align-center ml-3">
          <v-speed-dial
            v-model="menuExt"
            direction="bottom"
            right
            transition="slide-y-reverse-transition"
            style="z-index: 3"
          >
            <template v-slot:activator>
              <v-btn v-model="menuExt" depressed color="primary" dark>
                <v-icon v-if="menuExt">mdi-close</v-icon>
                <v-icon v-else>mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dense
                  fab
                  small
                  depressed
                  @click="toggleDialogMaster"
                >
                  <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Master</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dense
                  fab
                  small
                  depressed
                  @click="toggleFormDialog(false)"
                >
                  <v-icon color="primary">mdi-plus-box</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("routes.extracurricular.short") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dense
                  fab
                  small
                  depressed
                  @click="$router.push({ name: 'ExtracurricularMember' })"
                >
                  <v-icon color="primary">mdi-exclamation</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("extracuricular.new_member") }}</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </div>
    </div>

    <v-divider class="my-4" />

    <table-ext
      :dataTable="dataTable"
      :actionDelete="isAdmin"
      :actionView="isAdmin"
      :actionRequest="isStudent"
      :paginate="false"
      @viewStudent="viewStudent"
      @toggleFormDialog="toggleFormDialog"
      @paginateChange="paginateChange"
      @request="showConfirm"
    />

    <DialogMaster
      :isDialog="isDialogMaster"
      @toggleDialog="toggleDialogMaster"
    />
    <FormDialog
      :isDialog="dialogForm"
      :isEdit="dataEdit"
      @save="toggleFormDialog(true)"
      @cancel="toggleFormDialog(false)"
    />
    <DialogList
      :isDialog="isDialogList"
      @closeDialog="toggleDialogList"
      :paramExtra="itemExtra"
    />
    <ModalConfirm
      :content="$t('extracuricular.dialog.confirm_request')"
      :close="() => (this.confirmAccept = false)"
      :isOpen="confirmAccept"
      :save="request"
      :loading="loadingConfirm"
    />
    <v-overlay :value="loadingOverlay" :z-index="6">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-card>
</template>

<script>
import {
  getListExt,
  listTeacher,
  fetchByIdExtra,
  memberRequest
} from "@/api/admin/academic/extracurricular";
import { masterPlace, masterGrade } from "@/api/masterData";
import i18n from "@/i18n";
import { mapGetters } from "vuex";

let typingTimer;
const doneTypingInterval = 750;

export default {
  metaInfo: {
    title: i18n.t("routes.extracurricular.long"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    DialogMaster: () => import("./components/Master"),
    FormDialog: () => import("./components/Form"),
    DialogList: () => import("./components/ListStudents"),
    TableExt: () => import("./components/TableExt"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters(["g_user", "g_role_type"])
  },
  watch: {
    search() {
      this.searchHandler();
    }
  },
  data() {
    return {
      menuExt: false,
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      isStudent: this.$store.getters.g_role_type === "STUDENT",
      loadingOverlay: false,
      isDialogMaster: false,
      dialogForm: false,
      sheet: false,
      excel: {
        [this.$i18n.t("master_data.table.name")]: "nama",
        [this.$i18n.t("extracuricular.total_student")]: "anggota",
        Detail: {
          field: "desc",
          callback: value => {
            let description = "";
            value.map(r => {
              const coach = [];
              let coachJoin = "";
              if (r?.coach?.length > 0) {
                r.coach.map(c => {
                  coach.push(c.name);
                });
                coachJoin = coach.join(", ");
              }
              description +=
                `${coachJoin} | ${r.day} | ${r.hours} | ${r.place ?? ""}` +
                `<br />`;
            });
            return description;
          }
        },
        [this.$i18n.t("extracuricular.description")]: "description"
      },
      search: "",
      loading: false,
      dataTable: {
        loadingCheck: false,
        loadingTable: false,
        data: []
      },
      isDialogList: false,
      itemExtra: {},
      dataEdit: {},
      idExtra: 0,
      confirmAccept: false,
      loadingConfirm: false
    };
  },
  methods: {
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async request() {
      this.dataTable.loadingCheck = true;
      this.loadingConfirm = true;
      const body = {
        extra: this.idExtra,
        student: this.g_user.student.student
      };
      const response = await memberRequest(body);
      if (response.data.code) {
        this.snackBar(true, this.$i18n.t("extracuricular.msg.member_request"));
      } else {
        this.snackBar(false, response.data.message);
      }
      this.dataTable.loadingCheck = false;
      this.loadingConfirm = false;
      this.confirmAccept = false;
      this.getListData();
    },
    showConfirm(item) {
      if (item.is_join === 0) {
        this.idExtra = item.id;
        this.confirmAccept = true;
      }
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.getListData();
      }, doneTypingInterval);
    },
    initData() {
      listTeacher().then(d =>
        this.$store.commit("GET_LIST_TEACHER", d.data.data)
      );
      masterPlace().then(d =>
        this.$store.commit("GET_MASTER_PLACE", d.data.data)
      );
      masterGrade().then(d =>
        this.$store.commit("GET_MASTER_GRADE", d.data.data)
      );
      this.getListData();
    },
    getListData() {
      this.dataTable.loadingTable = true;
      getListExt({
        search: this.search,
        limit: 100
      }).then(res => {
        if (res.data.code) {
          const r = res.data.data;
          this.dataTable.data = this.isTeacher
            ? r.filter(item => item.is_join === 1)
            : r;
        }
        this.dataTable.loadingTable = false;
      });
    },
    toggleDialogMaster() {
      this.isDialogMaster = !this.isDialogMaster;
    },
    async toggleFormDialog(flag) {
      if (flag instanceof Object) {
        this.loadingOverlay = true;
        this.dataEdit = await fetchByIdExtra(flag).then(d => d.data.data);
        if (this.dataEdit) this.loadingOverlay = false;
      }
      this.dialogForm = !this.dialogForm;
      if (typeof flag === "boolean" && flag) this.initData();
      if (!flag) this.dataEdit = {};
    },
    toggleDialogList() {
      this.isDialogList = !this.isDialogList;
      this.getListData();
    },
    viewStudent(dataStudents) {
      this.isDialogList = true;
      this.itemExtra = dataStudents;
    },
    paginateChange(page, limit) {
      this.dataTable.page = page;
      this.dataTable.limit = limit;
      this.initData();
    }
  }
};
</script>
